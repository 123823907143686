import React from 'react'
import { Box } from 'theme-ui'
import Navigation from '@components/Navigation'
import Drawer from '@components/Drawer'
import useSiteMetadata from '@helpers/useSiteMetadata'

const styles = {
  desktopMenu: {
    display: [`none`, `none`, `block`]
  },
  mobileMenu: {
    display: [`block`, `block`, `none`]
  },
  desktopMenuWrapper: {
    justifyContent: 'flex-end'
  }
}

export const HeaderMenu = ({ locationState, mobileMenu = {} }) => {
  const { headerMenu } = useSiteMetadata()

  const headerMenu2 = [
    { 
      name: 'Pour débuter',
      slug: '/bienvenue-dans-ce-cours-sur-les-options',
    },
    {
      name: 'Mes formations',
      slug: '/gqqfm',
    },
    {
      name: 'Blog',
      slug: '/plan-du-site',
    },
    {
      name: 'Mon parcours',
      slug: '/gael-deballe-formation-produits-derives',
    },
    {
      name: 'Me contacter',
      slug: '/gael-deballe-formation-produits-derives',
    }
  ];

  const desktopMenuNav = (
    <Navigation
      variant='horizontal'
      items={headerMenu}
      wrapperStyle={styles.desktopMenuWrapper}
    />
  )

  const mobileMenuNav = (
    <Drawer width='70%' locationState={locationState}>
      <Navigation
        variant='vertical'
        headingProps={{ as: 'h3' }}
        items={[
          {
            title: 'Menu',
            items: headerMenu2
          },
          mobileMenu
        ]}
      />
    </Drawer>
  )

  return (
    <>
      <Box sx={styles.desktopMenu}>{desktopMenuNav}</Box>
      <Box sx={styles.mobileMenu}>{mobileMenuNav}</Box>
    </>
  )
}
